import React from "react";
import {
  allBulletPointsLength,
  getSelectedTitleVariation,
  handleBulletPointsLiveStatus,
  handleTitleLiveStatus,
  maxDescriptionLength,
  maxSearchTermLength,
  maxTitleLength,
} from "./config";
import TabCard from "./TabCard/index";
import { formatBulletsPoints } from "../../../../../utils/helpers/strings";
import { useSelector } from "react-redux";
import { DbUserSelector, UserEmailSelector } from "../../../../../store/user/user.selector";
import {
  enableEditFunctionalityOnDetailPage,
  enableEditFunctionalityOnDetailPageCompany,
} from "../../../../../utils/helpers/priviligesChecks";
import { isManualKeywordAccess } from "../../../../../utils/helpers/permission";
import {
  BulletFormatEnum,
  ContentStatusEnum,
  // ContentStatusEnum,
  ProductDetailsEditableKeys,
  SwipeableTabTitlesEnums,
} from "../../../../../utils/constants/enums";
import BulletPointsWrapper from "../../../../Molecules/BulletPointsWrapper";
import { CompleteProductSelector } from "../../../../../store/product/productDetails/productDetails.selectors";
import { CompanySelector } from "../../../../../store/company/company.selector";

interface ProductDetailsTabProps {
  aiGenerated?: boolean;
}

const showBullets = (
  bullets: string[],
  editableBullets: string[],
  title?: string,
  _format?: BulletFormatEnum,
  _highlighted: string = "",
  liveProperty: boolean = false,
  liveTag: boolean = false,
  isNewBulletLayout = false
) => {
  return (
    <>
      {bullets && bullets?.length > 0 && (
        <>
          {bullets.map((bullet, index) => (
            <div key={bullet + index} style={{ border: "1px solid transparent" }}>
              {index < 5 && (
                <TabCard
                  body={bullet}
                  heading={index === 0 ? title : ""}
                  aiGenerated
                  liveTag={liveTag && index === 0}
                  liveProperty={liveProperty}
                  showLiveClasses={liveTag}
                  isNewBulletLayout={isNewBulletLayout}
                  productKey={ProductDetailsEditableKeys.BULLET_POINTS_BY_CHAT}
                  bulletIndex={index}
                  comparableValue={Array.isArray(editableBullets) ? "" : bullet}
                />
              )}
            </div>
          ))}
        </>
      )}
    </>
  );
};
const ProductDetailsTab: React.FC<ProductDetailsTabProps> = ({ aiGenerated }) => {
  const product = useSelector(CompleteProductSelector);
  const currentUser = useSelector(DbUserSelector);
  const userEmail = useSelector(UserEmailSelector);
  const isManualKeyword = isManualKeywordAccess(userEmail);
  const currentCompany = useSelector(CompanySelector);

  const bulletPointsLength = product?.editedGeneratedProductContent?.bulletPoints
    ? product?.editedGeneratedProductContent?.bulletPoints?.join("").length
    : product?.bulletPointsByChat
    ? product?.bulletPointsByChat.join("").length
    : 0;

  let isOwnProduct: boolean = false;
  try {
    isOwnProduct = currentUser._id === product?.userID;
  } catch (_e) {
    isOwnProduct = false;
  }
  return (
    <div className={"d-flex flex-column"}>
      <TabCard
        body={aiGenerated ? getSelectedTitleVariation(product) : product?.title}
        showKeywordDensity={aiGenerated ? true : false}
        heading={SwipeableTabTitlesEnums.TITLE}
        textLength={maxTitleLength}
        liveProperty={aiGenerated ? product?.productLive?.isTitleLive || false : false}
        liveTag={aiGenerated && (product?.showLiveCheck || handleTitleLiveStatus(product))}
        aiGenerated={aiGenerated}
        // comparableValue={
        //   aiGenerated
        //     ? product?.status === ContentStatusEnum.APPROVED || product?.status === ContentStatusEnum.PUBLISHED
        //       ? product?.approvedProduct?.generatedTitle
        //       : product?.editedGeneratedProductContent?.title !== undefined
        //       ? product?.editedGeneratedProductContent?.title
        //       : product?.generatedTitle
        //     : product?.title
        // }
        comparableValue={aiGenerated ? getSelectedTitleVariation(product) : product?.title}
        showLiveClasses={aiGenerated && (product?.showLiveCheck || handleTitleLiveStatus(product))}
        showEditButton={
          (enableEditFunctionalityOnDetailPage(currentUser?.role, product?.status) && aiGenerated) ||
          isOwnProduct ||
          ((enableEditFunctionalityOnDetailPageCompany(currentCompany?.role, product?.status) ||
            currentUser?.companyID) &&
            aiGenerated)
        }
        productKey={ProductDetailsEditableKeys.TITLE}
        showTitleVariations={aiGenerated}
      />

      {aiGenerated ? (
        <div>
          <div>
            <BulletPointsWrapper
              isLong={bulletPointsLength > allBulletPointsLength}
              body={
                product?.bulletPointsByChatEdited?.length
                  ? product?.bulletPointsByChatEdited.join("")
                  : product?.bulletPointsByChat
                  ? product?.bulletPointsByChat.join("")
                  : ""
              }
              // heading={"Bullet Points"}
              heading={SwipeableTabTitlesEnums.BULLET_POINTS}
              // isLive={!!product?.showLiveCheck}
              isLive={!!(product?.showLiveCheck || handleBulletPointsLiveStatus(product))}
              liveProperty={!!product?.productLive?.isBulletPointsLive}
              showEditButton={
                enableEditFunctionalityOnDetailPage(currentUser?.role, product?.status) ||
                isOwnProduct ||
                ((enableEditFunctionalityOnDetailPageCompany(currentCompany?.role, product?.status) ||
                  currentUser?.companyID) &&
                  aiGenerated)
              }
              editableKey={ProductDetailsEditableKeys.BULLET_POINTS_BY_CHAT}
            >
              {showBullets(
                product?.bulletPointsByChat,
                product?.editedGeneratedProductContent?.bulletPoints || [],
                "",
                product.bulletPointsFormat,
                product?.highlightedKeyword,
                product?.productLive?.isBulletPointsLive,
                false,
                true
              )}
            </BulletPointsWrapper>
          </div>
          <TabCard
            body={aiGenerated ? product.searchTerm : product?.searchTerm}
            betaTag={false}
            aiGenerated={aiGenerated}
            heading={"Generic Keywords (Search Terms)"}
            textLength={maxSearchTermLength}
            showWithoutSpaces={true}
            comparableValue={
              aiGenerated
                ? product?.status === ContentStatusEnum.APPROVED || product?.status === ContentStatusEnum.PUBLISHED
                  ? product?.approvedProduct?.searchTerm
                  : product?.editedGeneratedProductContent?.searchTerm !== undefined
                  ? product?.editedGeneratedProductContent?.searchTerm
                  : product?.searchTerm
                : product?.searchTerm
            }
            showEditButton={true}
            productKey={ProductDetailsEditableKeys.SEARCH_TERMS}
          />
        </div>
      ) : (
        product?.bulletPoints?.map((bullet: string, index: number) => (
          <TabCard
            key={bullet}
            body={formatBulletsPoints(bullet)}
            comparableValue={formatBulletsPoints(bullet)}
            heading={index === 0 ? "Feature Bullets" : ""}
            productKey={ProductDetailsEditableKeys.BULLET_POINTS}
          />
        ))
      )}
      <TabCard
        body={aiGenerated ? product?.generatedDescription : product?.description}
        showKeywordDensity={aiGenerated ? true : false}
        aiGenerated={aiGenerated}
        betaTag={false}
        // heading={"Product Description"}
        heading={SwipeableTabTitlesEnums.DESCRIPTION}
        comparableValue={
          aiGenerated
            ? product?.editedGeneratedProductContent?.description !== undefined
              ? product?.editedGeneratedProductContent?.description
              : product?.generatedDescription
            : product?.description
        }
        textLength={maxDescriptionLength}
        showEditButton={
          ((enableEditFunctionalityOnDetailPage(currentUser?.role, product?.status) || currentUser?.companyID) &&
            aiGenerated) ||
          isOwnProduct
        }
        productKey={ProductDetailsEditableKeys.DESCRIPTION}
      />
      {isManualKeyword && (
        <div>
          {showBullets(
            product?.bulletPointsByPayton || [],
            product?.bulletPointsByPayton || [],
            "Bullet Points By Payton",
            product?.bulletPointsFormat,
            product?.highlightedKeyword
          )}
        </div>
      )}
    </div>
  );
};

export default ProductDetailsTab;
