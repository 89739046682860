import React, { useRef, useState } from "react";
import { debounce } from "lodash";

import ParentVariationsToolbar from "./ParentVariationsToolbar";
import classes from "./index.module.css";
import { toast } from "react-toastify";
import ParentVariationsGrid from "./ParentVariationsGrid/ParentVariationsGrid";


export type ParentVariationsState = {
  loading: boolean;
  csvLoading: boolean;
};

const ParentVariations = () => {
  const [state] = useState<ParentVariationsState>({
    loading: false,
    csvLoading: false,
  });

  const [searchQuery, setSearchQuery] = useState<string>("");
  const [search, setSearch] = useState<string>(searchQuery);

  const debouncedSearch = useRef(
    debounce((value: string) => {
      setSearchQuery(value);
    }, 1500)
  ).current;

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setSearch(e.target.value);
    debouncedSearch(e.target.value);
  };

  const handleRefresh = () => {
    toast.success("refresh clicked");
  };

  const handleFilterClick = (e: React.MouseEvent<HTMLDivElement>) => {
    console.log(e);
    toast.success("filter click");
  };

  const handleExportAllCsvClick = () => {
    toast.success("export click");
  };
  const hide = false

  return (
    <div className={classes.Container}>
      {hide && <ParentVariationsToolbar
        handleChange={handleChange}
        search={search}
        state={state}
        handleRefresh={handleRefresh}
        handleFilterClick={handleFilterClick}
        handleExportAllCsvClick={handleExportAllCsvClick}
      />}
      <ParentVariationsGrid />
    </div>
  );
};

export default ParentVariations;
