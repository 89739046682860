import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch } from "react-redux";

import MainLoader from "./components/Atoms/MainLoader";
import AppRoutes from "./routes/routes";

import { useDialog } from "./components/Atoms/Dialog/useDialog";
import { setRoleDispatch, setUserDispatch } from "./store/user/user.actions";
import { establishUserLoginSession } from "./apis/auth";
import { errorHandler } from "./utils/helpers/apis";

import PricingContext from "./context/PricingContext";
import Layout from "./components/Layout";

import "./index.css";
import { setCompanyAction } from "./store/company/company.actions";

import Logo from "./assets/png/robo.png";

import { LicenseInfo } from "@mui/x-data-grid-premium";

LicenseInfo.setLicenseKey('c9d57dec779eff96e47d09776b67d229Tz0xMDYzMDIsRT0xNzY5MDM5OTk5MDAwLFM9cHJlbWl1bSxMTT1zdWJzY3JpcHRpb24sUFY9aW5pdGlhbCxLVj0y');


interface AppState {
  isLoading: boolean;
}

//This is an example of how to define the props in a component
interface Props {
  id?: string;
}

const App: React.FC<Props> = () => {
  const { isOpen, onOpen, onClose } = useDialog();
  const [state, setState] = useState<AppState>({
    isLoading: true,
  });

  const maintain = false;

  const dispatch = useDispatch();

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    setState({
      ...state,
      isLoading: true,
    });

    establishUserLoginSession()
      .then((resp) => {
        if (resp?._id) {
          dispatch(setUserDispatch(resp));
          dispatch(setRoleDispatch(resp?.role));
          if (resp?.companyID) {
            dispatch(setCompanyAction(resp?.companyID));
          }
        }
        setState({ ...state, isLoading: false });
      })
      .catch((error: any) => {
        setState({ ...state, isLoading: false });
        toast.error(errorHandler(error));
      });
  }, []);

  if (maintain) {
    return (
      <div className={"d-flex flex-column mt-5 align-items-center justify-content-center px-5"}>
        <img src={Logo} alt="" height={300} />
        <h2 className={"text-center text-black-50"}>
          AutoMato is currently undergoing some routine upgrades and will be down for the next 5 hours. Thank you for
          your patience.
        </h2>
      </div>
    );
  } else if (state.isLoading) return <MainLoader />;
  else
    return (
      <PricingContext.Provider
        value={{
          isOpen,
          onClose,
          onOpen,
        }}
      >
        <Layout>
          <div className="App">
            <ToastContainer />
            <AppRoutes />
          </div>
        </Layout>
      </PricingContext.Provider>
    );
};

export default App;
