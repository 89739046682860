import * as React from "react";
import { DataGridProps } from "@mui/x-data-grid";
import {
  DataGridPremium, useKeepGroupedColumnsHidden, useGridApiRef,GridRowParams
} from "@mui/x-data-grid-premium";
import { defaultPageSizeOptions } from "../../../../hooks/usePagination";
import MainLoader from "../../../Atoms/MainLoader";
import MCheckbox from "../../../Atoms/MCheckbox";
import classes from '../ProductDataGrid/ProductTable/index.module.css'
import { scrollColor } from "../../../../utils/constants/colors";
import NoResultsFound from "../../../Atoms/NoResultsFound/NoResultsFound";
// import { accordionClasses } from "@mui/material";

interface MuiDataTableProps extends DataGridProps {
  withoutPagination?: boolean;
  setPageNumber: (page: number) => void;
  setPageSize: (page: number) => void;
  pageNumber: number;
  total: number;
  setSortModel?: (sortBy: string, sortOrder: number) => void;
  noPadding?: boolean;
  sortModel?: any;
  showHeaderCheckBox?: boolean;
}

export const VariationGrid = (props: MuiDataTableProps) => {
  const {
    sortModel,
    pageNumber,
    pageSize,
    setPageNumber,
    setPageSize,
    setSortModel = () => {},
    showHeaderCheckBox = false,
  } = props;

  const isRowSelectable = (params: GridRowParams) => {
    return props.isRowSelectable?.(params) ?? true;
    // return false
  };

  const getRowClassName = (params: GridRowParams) => {
    const isSelectable = isRowSelectable(params);
    // const value = params.row?.relevancy;

    if (!isSelectable) {
      return "not-selectable";
    } else {
      return "";
    }
  };

  /* eslint-disable react-hooks/exhaustive-deps */
  const memoizedColumns = props.columns

  const components = {
    ...props.components,
    BaseCheckbox: (prop: any) => <MCheckbox variant={"square"} {...prop} />,
    LoadingOverlay: () => <MainLoader dancingRobot />,
    NoRowsOverlay: () => <NoResultsFound />,
  };
  const apiRef = useGridApiRef();

  /* eslint-disable react-hooks/exhaustive-deps */

  const orderObject = {
    desc: -1,
    asc: 1,
  };

  const initialState = useKeepGroupedColumnsHidden({
    apiRef,
    initialState: {
      rowGrouping: {
        model: ['groupBy'],

      },
    },
  });



  return (
    <>
      <DataGridPremium
        apiRef={apiRef}
        columns={memoizedColumns}
        rowHeight={70}
        rows={props.rows}
        pageSize={pageSize}
        onPageSizeChange={(newPage: React.SetStateAction<number>) => {
          setPageSize(newPage as number);
        }}
        disableVirtualization={true}
        sortingOrder={["desc", "asc"]}
        rowCount={props.total}
        rowsPerPageOptions={defaultPageSizeOptions}
        paginationMode={"server"}
        rowGroupingColumnMode="single"
        initialState={initialState}
        page={pageNumber}
        // filterMode="server"
        // sortingMode={"server"}
        isRowSelectable={props.isRowSelectable}
        components={components}
        loading={props.loading}
        hideFooterPagination={props.withoutPagination}
        onCellClick={props.onCellClick}
        getRowClassName={getRowClassName}
        onPageChange={(page) => {
          setPageNumber(page);
        }}
        sortModel={sortModel}
        onSortModelChange={(_model, _details) => {
          const filter = _model[0];
          if (filter.sort) {
            setSortModel(filter.field, orderObject[filter.sort]);
          }
        }}
        checkboxSelection={props.checkboxSelection}
        onSelectionModelChange={props.onSelectionModelChange}
        selectionModel={props.selectionModel}
        disableColumnMenu
        disableSelectionOnClick
        pagination={true}
        sx={
          props.withoutPagination
            ? {
              pointerEvents: props.loading ? "none" : "auto",
              "& ::-webkit-scrollbar": {
                width: "8px !important",
                height: "8px !important",
                borderRadius: "2px",
              },
              "& ::-webkit-scrollbar-track": {
                background: "#f1f1f1 !important",
              },
              "& ::-webkit-scrollbar-thumb": {
                backgroundColor: `${scrollColor} !important`,
              },
              "& ::-webkit-scrollbar-thumb:hover": {
                background: "#555 !important",
              },

              "& .MuiTablePagination-displayedRows": {
                display: "none !important",
              },
              "& .MuiTablePagination-actions": {
                display: "none !important",
              },

              "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer": {
                display: showHeaderCheckBox ? "" : "none !important",
              },
            }
            : {
              pointerEvents: props.loading ? "none" : "auto",
              "& ::-webkit-scrollbar": {
                width: "8px !important",
                height: "8px !important",
                borderRadius: "2px",
              },
              "& ::-webkit-scrollbar-track": {
                background: "#f1f1f1 !important",
              },
              "& ::-webkit-scrollbar-thumb": {
                backgroundColor: `${scrollColor} !important`,
              },
              "& ::-webkit-scrollbar-thumb:hover": {
                background: "#555 !important",
              },
              "& .MuiInputBase-root": {
                width: "auto !important",
              },
              "& .MuiTablePagination-selectLabel ": {
                marginTop: "14px !important",
              },
              "& .MuiTablePagination-displayedRows ": {
                marginTop: "14px !important",
              },
            }
        }
        classes={{
          root: classes.MuiDataGridRoot,
          "cell--withRenderer": classes.CellWithRenderer,
          columnHeaders: classes.ColumnHeaders,
          footerContainer: classes.FootContainer,
          columnSeparator: classes.ColumnSeparator,
        }}
      />
    </>
  );
};

export default VariationGrid;
